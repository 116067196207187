/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import HomePageCardTableRow from "./HomePageCardTableRow";
import TableRowKeyValuePair from "./TableRowKeyValuePair";
import { TicketResource } from "../../types/case";

interface UserWatchlistTableProps {
  data: TicketResource[];
}

const TicketTable: React.FC<UserWatchlistTableProps> = ({ data }) => {
  return (
    <>
      {data.map((ticket) => (
        <HomePageCardTableRow>
          <TableRowKeyValuePair
            title="Case #"
            value={ticket.caseId}
            elementWidth="20%"
            valueType="navLink"
            linkHref={`/review/case/${ticket.caseId}`}
          />
          <TableRowKeyValuePair
            title={"Org Name"}
            value={ticket.org_name || "Unknown"}
            elementWidth={"30%"}
            valueType="navLink"
            linkHref={"/accounts/" + ticket.case_org_id}
          />
          <TableRowKeyValuePair
            title="Solution"
            value={ticket.case_solution}
            elementWidth="25%"
          />
          <TableRowKeyValuePair
            title="Status"
            value={ticket.case_status}
            elementWidth="15%"
          />
        </HomePageCardTableRow>
      ))}
    </>
  );
};

export default TicketTable;

export default class Template {
  constructor(protected templateText: string) {}

  /**
   * Captures content between curly braces
   * - Surrounded by {{ and }}
   *   - Capture group 1:
   *     - One or more of (non capturing group)
   *       - Any character except curly braces
   *       - OR
   *       - Any character preceded by a backslash
   * @remarks See also {@link https://surma.dev/things/regexp-quote/}
   */
  private static regex = /{{((?:[^{}]|\\.)*)}}/g;

  render(data: Record<string, string>) {
    const before = this.templateText;
    const after = before.replace(Template.regex, (_, match) => {
      const key = match.trim();
      return data[key] ?? key;
    });
    return after;
  }
}

/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

declare global {
  interface Window {
    _satellite?: Satellite;
    dataLayer?: Record<string, unknown>;
  }
}

interface Satellite {
  track: (event: string, data?: Record<string, unknown>) => void;
}

function getSatellite(): Satellite | undefined {
  return window._satellite;
}

export function track(...args: Parameters<Satellite["track"]>) {
  const satellite = getSatellite();
  if (satellite) {
    satellite.track(...args);
  }
}

export function addToDataLayer<T = unknown>(data: T) {
  const original = window.dataLayer ?? {};
  window.dataLayer = {
    ...original,
    ...data,
  };
}

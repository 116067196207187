/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */
import JiraP2EDashboard from "../../pages/JiraP2EDashboard";
import { IMenuItem } from "../../types/ISideNavbar";

const AdobeExpPlatformMenuData: IMenuItem[] = [
  {
    Solution: "Adobe Exp Platform",
    LinkName: "UPS Ingestion Queue, Alerts, and Sampling Metrics",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/ingestion/ups/queue",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "UPS Ingestion Queue, Alerts, and Sampling Metrics",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/pages/viewpage.action?spaceKey=AEPLT&title=Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Customer Provisioning and entitelments information",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/provisioning/summary",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Add Data Lineage to GLASS v1 - Batch Destinations",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Glass- Multi Customer Reporting - Phase 2",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/organizations/browse",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Entitlements and License Usage",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName:
      "Glass-Alerting Integration: Phase 1 and 2: Alert Filtering in Glass",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/alerts/history?temporalConstraintData=preset%3Alast7Days&temporalConstraintType=dateRangePicker",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Alert Filtering in Glass and Show Alerts in Glass",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Fields and app information in GLASS ",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Provide a way for users to know more information about different fields in the app.",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Surface License Usage data into GLASS ",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/provisioning/licenseUsage",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "License usage metrics to troubleshoot root cause of overages for our customers",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Profile Ingestion Queue for Batch and Streaming",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/ingestion/ups/queue",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "Profile Ingestion Queue for Batch and Streaming",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Transparency",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription:
      "Correlation between Ingestion and activation, Provisioning metadata, BAtch activation: ability to track activation (segmentation, export, activatioin) end to end, and ability to track ingestion E2E at the granular level ",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "GLASS tools : TTL Settings",
    LinkURL:
      "https://cetoolingglassui-prod-va6.cloud.adobe.io/{orgId}/sname:prod/ingestion/ups/ttlBrowse",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink:
      "https://wiki.corp.adobe.com/display/AEPLT/Glass+-+Customer+Engineering+Tooling",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Columbus",
    LinkURL: "https://columbus.corp.adobe.com/",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "ABO / Login-As",
    LinkURL:
      "https://abo.services.adobe.com/customers/search?query={requestor_email}",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "Panorama",
    LinkURL: "https://pmg.corp.adobe.com/PanoramaECJ",
    Contextual: false,
    ContextualURLParameters: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "External",
    ParameterType: "",
  },
  {
    Solution: "Adobe Exp Platform",
    LinkName: "P2E Jira Dashboard",
    LinkURL: `/jira/:caseInfoSuffix`,
    Contextual: false,
    ContextualURLParameters: "",
    ParameterType: "",
    LinkDescription: "",
    PointOfContact: "",
    DocumentationLink: "",
    LinkType: "Internal",
    component: <JiraP2EDashboard />,
  },
];

export default AdobeExpPlatformMenuData;

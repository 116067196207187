/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import DOMPurify from "dompurify";

interface MarkdownRendererProps {
  text: string;
}

const customLinkRenderer = (props: any) => {
  const { href = "" } = props;
  try {
    const url = new URL(href);
    const allowedHosts = [
      "adobe.com",
      "adobe-ent.crm.dynamics.com",
      "github.com",
      "marketo.com",
      "adobe.sharepoint.com",
      "docs.fastly.com",
      "dev.mysql.com",
      "adobe.net",
      "slack.com",
    ];
    return allowedHosts.some((host) => url.hostname.endsWith(host)) ? (
      <a {...props} target={"_blank"}>
        {props.children}
      </a>
    ) : (
      <span>{href}</span>
    );
  } catch {
    return null;
  }
};

const customImageRenderer = (props: any) => {
  return null;
};

const AiMarkdownRenderer: React.FC<MarkdownRendererProps> = ({ text }) => {
  const sanitizedText = DOMPurify.sanitize(text);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      components={{
        a: customLinkRenderer,
        img: customImageRenderer,
      }}
    >
      {sanitizedText}
    </ReactMarkdown>
  );
};

export default AiMarkdownRenderer;

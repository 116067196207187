import React, { useState } from "react";
import { Flex } from "@adobe/react-spectrum";
import PageWrapper from "../components/common/PageWrapper";
import DataContainer from "../components/common/DataContainer";
import JiraP2EList from "../components/jiraP2E/JiraP2EList";
import JiraP2ECreate from "../components/jiraP2E/JiraP2ECreate";
import JiraP2ECustomFields from "../components/jiraP2E/JiraP2ECustomFields";

export const enum JiraSubpage {
  Landing = 0,
  Create,
  CustomFields,
}

const JiraP2EDashboard: React.FC = () => {
  const title = "Jira P2E Dashboard";
  document.title = title;
  const [subpage, setSubpage] = useState<JiraSubpage>(JiraSubpage.Landing);
  const [project, setProject] = useState<string | null>(null);
  const [typeOfRequest, setTypeOfRequest] = useState<string | null>(null);
  const [issueType, setIssueType] = useState<string | null>(null);

  switch (subpage) {
    case JiraSubpage.Create:
      return (
        <PageWrapper pageName={title}>
          <Flex direction="column" gap="size-400">
            <DataContainer label={title}>
              <JiraP2ECreate
                setSubpage={setSubpage}
                initialProject={project}
                initialIssueType={typeOfRequest}
                submitCallback={({ project, typeOfRequest, issueType }) => {
                  setProject(project);
                  setTypeOfRequest(typeOfRequest);
                  setIssueType(issueType);
                  setSubpage(JiraSubpage.CustomFields);
                }}
              />
            </DataContainer>
          </Flex>
        </PageWrapper>
      );
    case JiraSubpage.CustomFields:
      return (
        <PageWrapper pageName={title}>
          <Flex direction="column" gap="size-400">
            <DataContainer label={title}>
              <JiraP2ECustomFields
                setSubpage={setSubpage}
                project={project}
                typeOfRequest={typeOfRequest}
                issueType={issueType}
              />
            </DataContainer>
          </Flex>
        </PageWrapper>
      );
    default:
      return (
        <PageWrapper pageName={title}>
          <Flex direction="column" gap="size-400">
            <DataContainer label={title}>
              <JiraP2EList setSubpage={setSubpage} />
            </DataContainer>
          </Flex>
        </PageWrapper>
      );
  }
};

export default JiraP2EDashboard;

/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import HomePageCardTableRow from "./HomePageCardTableRow";
import TableRowKeyValuePair from "./TableRowKeyValuePair";
import { GetUserKBArticlesResponse } from "../../types/knowledgeCenter";

interface UserWatchlistTableProps {
  data: GetUserKBArticlesResponse;
}

const UserKbArticlesTable: React.FC<UserWatchlistTableProps> = ({ data }) => {
  return (
    <>
      {data.data.map((kbArticle) => (
        <HomePageCardTableRow>
          <TableRowKeyValuePair
            title={"Case #"}
            value={kbArticle.dynamics_id}
            elementWidth={"25%"}
            valueType={"navLink"}
            linkHref={`/review/case/${kbArticle.dynamics_id}`}
          />
          <TableRowKeyValuePair
            title={"Jira Id"}
            value={kbArticle.jira_id}
            elementWidth={"25%"}
            valueType={"link"}
            linkHref={`${window.env.REACT_APP_JIRA_TICKET_BASE_URL}browse/${kbArticle.jira_id}`}
          />
          <TableRowKeyValuePair
            title={"Article Type"}
            value={kbArticle.is_public ? "Public" : "Internal"}
            elementWidth={"25%"}
          />
          <TableRowKeyValuePair
            title={"Status"}
            value={kbArticle.status}
            elementWidth={"25%"}
          />
        </HomePageCardTableRow>
      ))}
    </>
  );
};

export default UserKbArticlesTable;

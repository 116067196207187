/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuItem } from "../types/ISideNavbar";
import AdobeAnalyticsMenuData from "./products/adobeAnalytics";
import AdobeCampaignMenuData from "./products/adobeCampaign";
import AdobeExpManagerMenuData from "./products/adobeExpManager";
import AdobeExpPlatformMenuData from "./products/adobeExpPlatform";
import AllSolutionsMenuData from "./products/allSolutions";
import CommerceMenuData from "./products/commerce";
import MarketoMenuData from "./products/marketo";
import WorkfrontMenuData from "./products/workfront";
import MarketoMeasureMenuData from "./products/marketoMeasure";

const MenuData: IMenuItem[] = [
  ...AdobeAnalyticsMenuData,
  ...AdobeCampaignMenuData,
  ...AdobeExpManagerMenuData,
  ...AllSolutionsMenuData,
  ...CommerceMenuData,
  ...MarketoMenuData,
  ...WorkfrontMenuData,
  ...MarketoMeasureMenuData,
  ...AdobeExpPlatformMenuData,
];

export default MenuData;

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  codeBlockPlugin,
  codeMirrorPlugin,
  CodeToggle,
  DiffSourceToggleWrapper,
  headingsPlugin,
  InsertTable,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  sandpackPlugin,
  StrikeThroughSupSubToggles,
  tablePlugin,
  toolbarPlugin,
  UndoRedo,
  imagePlugin,
  InsertCodeBlock,
  ConditionalContents,
  SandpackConfig,
  ChangeCodeMirrorLanguage,
  diffSourcePlugin,
  MDXEditorMethods,
  ImageUploadHandler,
  InsertImage,
} from "@mdxeditor/editor";
import { View } from "@adobe/react-spectrum";
import { useState } from "react";

export interface MarkdownEditorProps {
  name: string;
  label: string;
  initialValue?: string;
  value?: string;
  mdxEditorRef?: React.RefObject<MDXEditorMethods>;
  onChange?: (markdown: string) => void;
  uploadHandler?: ImageUploadHandler;
  enableImageToolbar?: boolean;
}

export const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  name,
  label,
  initialValue = "",
  value,
  mdxEditorRef,
  onChange = () => null,
  uploadHandler,
  enableImageToolbar = false,
}) => {
  const simpleSandpackConfig: SandpackConfig = {
    defaultPreset: "react",
    presets: [],
  };
  const [editorValue, setEditorValue] = useState(initialValue);

  return (
    <View UNSAFE_style={{ width: "100%" }}>
      <label>{label}</label>
      <textarea
        value={value ? value : editorValue}
        name={name}
        readOnly
        hidden
      ></textarea>
      <View
        UNSAFE_style={{ width: "100%", overflow: "hidden" }}
        borderWidth="thin"
        borderColor="gray-400"
      >
        <MDXEditor
          ref={mdxEditorRef}
          markdown={value ? value : editorValue}
          onChange={(newText) => {
            if (!value) setEditorValue(newText);
            onChange(newText);
          }}
          plugins={[
            diffSourcePlugin({
              viewMode: "rich-text",
            }),
            headingsPlugin(),
            imagePlugin({
              imageUploadHandler: uploadHandler,
            }),
            listsPlugin(),
            quotePlugin(),
            tablePlugin(),
            linkPlugin(),
            quotePlugin(),
            markdownShortcutPlugin(),
            codeBlockPlugin({ defaultCodeBlockLanguage: "plaintext" }),
            sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
            codeMirrorPlugin({
              codeBlockLanguages: {
                sql: "SQL",
                css: "CSS",
                plaintext: "plaintext",
                bash: "bash",
                c: "c",
                cpp: "cpp",
                go: "Go",
                groovy: "groovy",
                html: "html",
                java: "java",
                js: "js",
                json: "json",
                perl: "perl",
                php: "php",
                python: "python",
                xml: "xml",
                yaml: "yaml",
              },
            }),
            toolbarPlugin({
              toolbarContents: () => (
                <DiffSourceToggleWrapper>
                  <BlockTypeSelect />
                  <BoldItalicUnderlineToggles />
                  <CodeToggle />
                  <StrikeThroughSupSubToggles />
                  <ListsToggle />
                  {enableImageToolbar ? <InsertImage /> : null}
                  <InsertTable />
                  <UndoRedo />
                  <ConditionalContents
                    options={[
                      {
                        when: (editor) => editor?.editorType === "codeblock",
                        contents: () => <ChangeCodeMirrorLanguage />,
                      },
                      { fallback: () => <InsertCodeBlock /> },
                    ]}
                  />
                  )
                </DiffSourceToggleWrapper>
              ),
            }),
          ]}
        />
      </View>
    </View>
  );
};

export default MarkdownEditor;

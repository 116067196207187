import React from "react";
import {
  View,
  Flex,
  Divider,
  ProgressCircle,
  Text,
  IllustratedMessage,
  Button,
  Heading,
} from "@adobe/react-spectrum";
import { JiraSubpage } from "../../pages/JiraP2EDashboard";
import { useJiraTicketsQuery } from "../../services/supportInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const JiraTicketCell: React.FC<
  React.PropsWithChildren<{
    flex?: string | number | boolean;
    label: string;
  }>
> = ({ label, children, flex }) => (
  <View padding="size-100" minWidth="size-1200" flex={flex}>
    <dt style={{ textTransform: "uppercase", opacity: 0.5 }}>{label}</dt>
    <View UNSAFE_style={{ fontSize: 18 }}>{children}</View>
  </View>
);

const JiraP2EList: React.FC<{
  setSubpage: React.Dispatch<React.SetStateAction<JiraSubpage>>;
}> = ({ setSubpage }) => {
  const caseObject = useSelector((state: RootState) => state.case.casePayload);
  const ticketId = caseObject?.caseId ?? "";
  const { data, error, isLoading } = useJiraTicketsQuery({ ticketId });

  if (isLoading)
    return <ProgressCircle aria-label="Loading…" isIndeterminate />;
  if (error || !data)
    return <h2>Error: {data?.meta?.message ?? "Unknown error"}</h2>;

  const tickets = data.data;

  return (
    <>
      <h2>
        {tickets.length === 1
          ? "1 Jira ticket has been created for this case"
          : `${tickets.length} Jira tickets have been created for this case`}
      </h2>
      <Flex direction="column" gap="size-100">
        {tickets.map((ticket) => (
          <View
            borderWidth="thin"
            borderColor="gray-300"
            padding="size-100"
            key={ticket.id}
          >
            <Flex direction="row" gap="size-100" alignItems="baseline">
              <JiraTicketCell label="Jira ID">
                <a
                  href={`${window.env.REACT_APP_JIRA_TICKET_BASE_URL}browse/${ticket.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ticket.id}
                </a>
              </JiraTicketCell>
              <Divider orientation="vertical" size="S" />
              <JiraTicketCell label="Issue Type">{ticket.type}</JiraTicketCell>
              <Divider orientation="vertical" size="S" />
              <JiraTicketCell label="Summary" flex>
                {ticket.summary}
              </JiraTicketCell>
              <Divider orientation="vertical" size="S" />
              <JiraTicketCell label="Type of Request">
                {ticket.requestType}
              </JiraTicketCell>
              <Divider orientation="vertical" size="S" />
              <JiraTicketCell label="Priority">
                {ticket.priority}
              </JiraTicketCell>
              <Divider orientation="vertical" size="S" />
              <JiraTicketCell label="Status">{ticket.status}</JiraTicketCell>
            </Flex>
          </View>
        ))}
        <IllustratedMessage margin="size-400">
          <Heading>Would you like to create a new P2E Jira ticket?</Heading>
          <Button
            variant="accent"
            onPress={() => setSubpage(JiraSubpage.Create)}
          >
            <Text>Create a new Jira ticket</Text>
          </Button>
        </IllustratedMessage>
      </Flex>
    </>
  );
};

export default JiraP2EList;

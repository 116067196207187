import React from "react";
import {
  Button,
  Flex,
  Picker,
  Item,
  ProgressCircle,
  IllustratedMessage,
} from "@adobe/react-spectrum";
import { JiraSubpage } from "../../pages/JiraP2EDashboard";
import { useJiraProjectsQuery } from "../../services/supportInsights";
import familyNameToCode from "../../utils/familyNameToCode";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { PROJECT_ISSUE_TYPES_KEY } from "./shared";

const JiraP2ECreate: React.FC<{
  setSubpage: React.Dispatch<React.SetStateAction<JiraSubpage>>;
  initialProject: string | null;
  initialIssueType: string | null;
  submitCallback: (a: {
    project: string;
    typeOfRequest: string;
    issueType: string;
  }) => void;
}> = ({ initialProject, initialIssueType, setSubpage, submitCallback }) => {
  const caseObject = useSelector((state: RootState) => state.case.casePayload);
  const productFamily = familyNameToCode(caseObject?.productFamilyName ?? "");
  const { data, isLoading, isError } = useJiraProjectsQuery({
    productFamily,
  });
  const [projectSelectedIndex, setProjectSelectedIndex] = React.useState<
    number | null
  >(null);
  const [typeOfRequestSelectedIndex, setTypeOfRequestSelectedIndex] =
    React.useState<number | null>(null);

  if (isLoading)
    return (
      <Flex gap="size-200" direction="column">
        <ProgressCircle isIndeterminate />
      </Flex>
    );

  if (isError || !data)
    return (
      <Flex gap="size-200" direction="column">
        <IllustratedMessage>
          {(data && data.meta && data.meta.message) ?? "An error occurred"}
        </IllustratedMessage>
      </Flex>
    );

  const projects = data.data;
  const projectTypesOfRequest =
    projectSelectedIndex === null
      ? {}
      : projects[projectSelectedIndex][PROJECT_ISSUE_TYPES_KEY];

  // FIXME: buggy, sometimes the form decides it doesnt want to shaw any initial value
  const selectedBefore =
    projects.findIndex((p) => p.project === initialProject) ?? "not found";

  return (
    <Flex gap="size-200" direction="column">
      <Flex gap="size-200">
        {/* {initialProject} {initialIssueType} {key} */}
        <Picker
          label="Project"
          isRequired
          onSelectionChange={(key) => setProjectSelectedIndex(+key)}
          defaultSelectedKey={selectedBefore + ""}
        >
          {projects.map(({ project }, index) => (
            <Item key={index}>{project}</Item>
          ))}
        </Picker>
        <Picker label="Issue Type" selectedKey="0" isDisabled>
          <Item key="0">Customer Request</Item>
        </Picker>
        <Picker
          label="Type of Request"
          isRequired
          isDisabled={projectSelectedIndex == null}
          onSelectionChange={(key) => setTypeOfRequestSelectedIndex(+key)}
          defaultSelectedKey={Object.keys(projectTypesOfRequest).find(
            (key) => projectTypesOfRequest[key] === initialIssueType,
          )}
        >
          {Object.entries(projectTypesOfRequest).map(([key, value]) => (
            <Item key={key}>{value}</Item>
          ))}
        </Picker>
      </Flex>
      <Flex gap="size-100">
        <Button
          variant="secondary"
          onPress={() => setSubpage(JiraSubpage.Landing)}
        >
          Back
        </Button>
        <Button
          variant="accent"
          onPress={() =>
            submitCallback({
              project: projects[projectSelectedIndex!].project,
              typeOfRequest: projectTypesOfRequest[typeOfRequestSelectedIndex!],
              issueType: "Customer Request",
            })
          }
          isDisabled={
            projectSelectedIndex == null || typeOfRequestSelectedIndex == null
          }
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default JiraP2ECreate;

import React from "react";
import { DOMRefValue } from "@react-types/shared";
import {
  Button,
  Flex,
  View,
  ProgressCircle,
  TextField,
  Heading,
  Form,
  Text,
  InlineAlert,
  Checkbox,
  Picker,
  Item,
  Badge,
  ButtonGroup,
  Grid,
  repeat,
} from "@adobe/react-spectrum";
import { JiraSubpage } from "../../pages/JiraP2EDashboard";
import {
  useJiraProjectFieldsQuery,
  useJiraSubmitFormMutation,
} from "../../services/supportInsights";
import {
  JiraField,
  useJiraPrefillFormQuery,
} from "../../services/supportInsights";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import familyNameToCode from "../../utils/familyNameToCode";
import { ToastQueue } from "@react-spectrum/toast";
import MarkdownEditor, { MarkdownEditorProps } from "../common/MarkdownEditor";
import Template from "./Template";
import { MDXEditorMethods } from "@mdxeditor/editor";
import j2m from "./j2m";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useNavigate, useLocation } from "react-router-dom";

const enum FormState {
  Authoring = 0,
  EditingDescription,
  Reviewing,
}

const shouldHideView = ({
  sectionName,
  formState,
}: {
  sectionName: string;
  formState: FormState;
}) => {
  switch (formState) {
    case FormState.Authoring:
      return sectionName === "Description";
    case FormState.EditingDescription:
      return sectionName !== "Description";
    default:
      return false;
  }
};

const transformContent = (
  content: string,
  fileMap: { [s: string]: string },
) => {
  let updatedContent = content;
  for (const [url, name] of Object.entries(fileMap)) {
    updatedContent = updatedContent.replace(url, name);
  }
  return updatedContent;
};

function cleanupImage(img: HTMLImageElement | null) {
  if (!img) return;

  // Remove any event listeners if attached
  img.onload = null;
  img.onerror = null;

  //unload the image from memory
  img.removeAttribute("src");

  // Remove the image from the DOM if it was added
  img.remove();

  // Eliminate the reference to the image
  img = null;
}

function sanitizeFilename(filename: string) {
  // Replace non-ASCII characters with an empty string
  return filename.replace(/[^\x00-\x7F]+/g, "_");
}

const JiraP2ECustomFields: React.FC<{
  setSubpage: React.Dispatch<React.SetStateAction<JiraSubpage>>;
  project: string | null;
  typeOfRequest: string | null;
  issueType: string | null;
}> = ({ setSubpage, project, typeOfRequest, issueType }) => {
  const caseObject = useSelector((state: RootState) => state.case.casePayload);
  const productFamilyName = caseObject?.productFamilyName;
  const productFamily = familyNameToCode(productFamilyName ?? "");
  const [formState, setFormState] = React.useState<FormState>(
    FormState.Authoring,
  );
  const [reviewed, setReviewed] = React.useState(false);
  const [submitTriggered, setSubmitTriggered] = React.useState(false);
  // const [description, setDescription] = React.useState<string>('');
  const mdxEditorRef = React.useRef<MDXEditorMethods | null>(null);
  const viewReference = React.useRef<DOMRefValue<HTMLElement> | null>(null);
  const [fileMap, setFileMap] = React.useState({});
  const [files, setFiles] = React.useState<File[]>([]);

  const handleFileChange = (event: { target: { files: any } }) => {
    // Get the newly selected files from the input
    const newFiles: FileList = event.target.files;

    // Create a new FileList object to append new files
    const updatedFiles = [
      ...files,
      ...Array.from(newFiles, (file: File) => {
        return new File([file], sanitizeFilename(file.name));
      }),
    ];

    // Update the state with the new array of files
    setFiles(updatedFiles);
    const dataTransfer = new DataTransfer();
    Array.from(updatedFiles).forEach((file) => dataTransfer.items.add(file));

    event.target.files = dataTransfer.files;
  };
  const scrollToTop = () => {
    viewReference.current?.UNSAFE_getDOMNode().scrollIntoView({
      behavior: "smooth",
    });
  };

  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    const handleBeforeUnload = (event: Event): string => {
      // Prevent the window from being closed
      event.preventDefault();
      return ""; // For some browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Block the user from navigating away
    const blockNavigation = () => {
      window.history.pushState(
        null,
        document.title,
        location.pathname + location.search,
      );
    };
    blockNavigation();

    // Handle the browser's back button
    const handleBack = () => {
      blockNavigation(); // Keep them on the same page by pushing to history
      if (
        window.confirm(
          "Are you sure you want to leave? You have unsaved changes!",
        )
      ) {
        window.history.go(-2);
      } else {
        window.history.go(1);
      }
    };

    window.addEventListener("popstate", handleBack);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handleBack);
    };
  }, [navigate, location]);

  const ticketId = caseObject?.caseId ?? "";
  const { data: fieldsQuery, isLoading } = useJiraProjectFieldsQuery({
    productFamily,
    project: project ?? "",
    issueType: issueType ?? "",
  });

  const fields = fieldsQuery?.data ?? {};

  const groupFieldsBySection = (
    fields: Record<string, Record<string, JiraField>>,
  ): Record<string, JiraField[]> => {
    const fieldGroups: Record<string, JiraField[]> = {};
    Object.entries(fields).forEach(([sectionName, sectionFields]) => {
      fieldGroups[sectionName] = Object.values(sectionFields);
    });
    return fieldGroups;
  };
  const fieldGroups = groupFieldsBySection(fields);

  const { data: prefillResponse, isLoading: isLoadingPrefillData } =
    useJiraPrefillFormQuery({
      productFamily,
      ticketId,
      fieldGroups: fieldGroups,
      project: project ?? "",
    });

  const [submitForm] = useJiraSubmitFormMutation();
  const prefillObject = fieldArrayToNameObject(prefillResponse?.data ?? []);

  if (!caseObject) {
    return <View>No case object found</View>;
  }

  if (isLoading || isLoadingPrefillData) {
    return (
      <View>
        <section>
          <Flex direction="row" gap="size-100">
            <Picker selectedKey="0" label="Project" isDisabled>
              <Item key="0">{project}</Item>
            </Picker>
            <Picker selectedKey="0" label="Issue Type" isDisabled>
              <Item key="0">Customer Request</Item>
            </Picker>
            <Picker selectedKey="0" label="Type of Request" isDisabled>
              <Item key="0">{typeOfRequest}</Item>
            </Picker>
          </Flex>
        </section>
        <View padding="size-100">
          <ProgressCircle isIndeterminate aria-label="Loading data..." />
        </View>
        <Flex direction="row" gap="size-100">
          <Button
            variant="secondary"
            onPress={() => setSubpage(JiraSubpage.Create)}
          >
            Back
          </Button>
        </Flex>
      </View>
    );
  }

  return (
    <View ref={viewReference}>
      <View width="100%" UNSAFE_className="search-table-wrapper">
        {submitTriggered && (
          <View
            backgroundColor="gray-400"
            padding="size-300"
            UNSAFE_className="search-table-loading-wrapper"
            UNSAFE_style={{ zIndex: 3 }}
          >
            <ProgressCircle
              aria-label="Loading…"
              staticColor="white"
              isIndeterminate
            />
          </View>
        )}
        <InlineAlert
          variant="info"
          isHidden={formState !== FormState.Reviewing}
        >
          <Text>
            Please take a moment to review the information before submitting.
          </Text>
        </InlineAlert>
        <Form
          validationBehavior="native"
          onInvalid={(e) => {
            e.preventDefault();
            scrollToTop();
          }}
          onSubmit={(ev) => {
            ev.preventDefault();
            const form = ev.nativeEvent.target as HTMLFormElement;
            const formData = new FormData(form);
            const markdown = mdxEditorRef.current?.getMarkdown() ?? "";
            const jiraFormattedText = j2m.toJira(markdown);
            formData.set(
              "description",
              transformContent(jiraFormattedText, fileMap) ?? "",
            );

            setSubmitTriggered(true);
            submitForm({
              ticketId,
              productFamily,
              issueType: typeOfRequest ?? "",
              project: project ?? "",
              formData,
            })
              .then((res) => {
                console.log({ res });
                const { error } = res;
                if (error) {
                  console.log({ error });
                  const { data } = error as any;
                  let message = data?.meta?.message;
                  if (
                    !message &&
                    (error as FetchBaseQueryError)?.status === "FETCH_ERROR"
                  ) {
                    message = "Network error";
                  }
                  ToastQueue.negative(
                    message
                      ? `Failed to create Jira ticket: ${message}`
                      : "Failed to create Jira ticket",
                    { timeout: 5000 },
                  );
                  return;
                }

                ToastQueue.positive("Ticket created successfully", {
                  timeout: 5000,
                });
                setSubpage(JiraSubpage.Landing);
              })
              .finally(() => {
                setSubmitTriggered(false);
              });
          }}
        >
          <section>
            <Flex direction="row" gap="size-100">
              <Picker selectedKey="0" label="Project" isDisabled>
                <Item key="0">{project}</Item>
              </Picker>
              <Picker selectedKey="0" label="Issue Type" isDisabled>
                <Item key="0">Customer Request</Item>
              </Picker>
              <Picker selectedKey="0" label="Type of Request" isDisabled>
                <Item key="0">{typeOfRequest}</Item>
              </Picker>
            </Flex>
          </section>
          <section>
            {Object.entries(fields).map(([groupKey, groupFields]) => (
              // All of the views are being rendered! This component takes the user step-by-step
              // through the form by hiding and showing the appropriate sections
              <View
                isHidden={shouldHideView({ sectionName: groupKey, formState })}
              >
                <Heading>{groupKey}</Heading>
                <Grid
                  gap="size-100"
                  marginY="size-200"
                  columns={repeat(2, "1fr")}
                >
                  {Object.values(groupFields).map((field) => {
                    const initialValue =
                      field.prefillSource?.type === "template"
                        ? field.value ?? field.prefillSource.data
                        : prefillObject[field.name]?.value ?? "";

                    let sharedAttributes: Record<string, unknown> = {
                      label: field.label,
                      name: field.name,
                      isRequired: field.required,
                      maxWidth: "100%",
                    };

                    const { data } = field;
                    switch (field.type) {
                      case "text":
                        return (
                          <TextField
                            {...sharedAttributes}
                            width="100%"
                            defaultValue={initialValue}
                          />
                        );
                      case "textarea":
                        const isDescriptionField =
                          groupKey === "Description" &&
                          field.name === "description";
                        let extraAttributes: Partial<MarkdownEditorProps> = {};
                        if (isDescriptionField) {
                          extraAttributes = {
                            mdxEditorRef,
                            onChange: (markdown) =>
                              requestAnimationFrame(() => {
                                // setDescription(markdown);
                                const newImage = markdown.match(
                                  /!\[[^\]]*\]\(([^)]+)\)/,
                                );
                                if (newImage) {
                                  const image = new Image();
                                  image.src = newImage[1];
                                  image.onload = function () {
                                    let imageHeight;
                                    let imageWidth;
                                    if (image.height > image.width) {
                                      imageHeight = 200;
                                      imageWidth =
                                        image.width * (200 / image.height);
                                    } else {
                                      imageWidth = 200;
                                      imageHeight =
                                        image.height * (200 / image.width);
                                    }
                                    const text = markdown.replace(
                                      /!\[[^\]]*\]\(([^)]+)\)/g,
                                      `<img height="${imageHeight}" width="${imageWidth}" src="$1" />`,
                                    );
                                    mdxEditorRef.current?.setMarkdown(text);
                                    cleanupImage(image);
                                  };
                                  // Error handling in case the image fails to load
                                  image.onerror = function () {
                                    ToastQueue.negative(
                                      "Failed to load image.",
                                      { timeout: 5000 },
                                    );
                                    cleanupImage(image);
                                  };
                                }
                              }),
                            uploadHandler: async (file) => {
                              const MAX_SIZE_MB = 100;
                              const sizeMB = file.size / (1024 * 1024);

                              if (sizeMB > MAX_SIZE_MB) {
                                ToastQueue.negative(
                                  `File too large (max ${MAX_SIZE_MB}MB)`,
                                  { timeout: 5000 },
                                );
                                throw new Error(
                                  `File too large (max ${MAX_SIZE_MB}MB)`,
                                );
                              }

                              const newFile = new File(
                                [file],
                                sanitizeFilename(file.name),
                              );
                              const attachments =
                                document.querySelector<HTMLInputElement>(
                                  "#jira-description-attachments",
                                );
                              if (attachments) {
                                // Add the uploaded file to the existing files
                                const dataTransfer = new DataTransfer();

                                dataTransfer.items.add(newFile);

                                const event = new Event("change", {
                                  bubbles: true,
                                });

                                attachments.files = dataTransfer.files;
                                attachments.dispatchEvent(event);
                              }

                              const url = URL.createObjectURL(file);
                              // Update state or global variable with the mapping of blob URL to original filename
                              setFileMap((prevMap) => ({
                                ...prevMap,
                                [url]: newFile.name,
                              }));

                              return url;
                            },
                            enableImageToolbar: true,
                          };
                        }
                        return (
                          <View gridColumn="span 2">
                            <MarkdownEditor
                              name={field.name}
                              label={field.label}
                              initialValue={initialValue}
                              {...extraAttributes}
                            />
                            {isDescriptionField && (
                              <>
                                <input
                                  style={{ marginTop: "10px" }}
                                  type="file"
                                  name="attachments[]"
                                  id="jira-description-attachments"
                                  onChange={handleFileChange}
                                />
                                <div style={{ marginTop: "10px" }}>
                                  <Text>The maximum file size is 100MB.</Text>
                                </div>
                              </>
                            )}
                          </View>
                        );
                      case "dropdown":
                        return (
                          <Picker
                            {...sharedAttributes}
                            defaultSelectedKey={initialValue}
                            flex
                            width="100%"
                            isDisabled={!data || !Object.keys(data).length}
                          >
                            {!data
                              ? []
                              : Object.entries(data).map(([key, value]) => (
                                  <Item key={key}>{value}</Item>
                                ))}
                          </Picker>
                        );
                      default:
                        return (
                          <Badge variant="negative">
                            <Text>
                              Invalid type <code>{field.type}</code> for the
                              field{" "}
                              <strong>
                                {field.label} ({field.name})
                              </strong>
                            </Text>
                          </Badge>
                        );
                    }
                  })}
                </Grid>
              </View>
            ))}
          </section>
          <section>
            <Flex
              direction="row"
              gap="size-100"
              isHidden={formState !== FormState.Reviewing}
              marginBottom="size-100"
            >
              <Checkbox onChange={(state) => setReviewed(state)}>
                <Flex direction="column">
                  <strong>Acknowledgement</strong>
                  <span>
                    I have read and verified all the information above. I wish
                    to promote this issue to Engineering.
                  </span>
                </Flex>
              </Checkbox>
            </Flex>
            <Flex direction="row" gap="size-100">
              <ButtonGroup>
                <Button
                  variant="secondary"
                  onPress={() =>
                    formState === FormState.Authoring
                      ? window.confirm(
                          "Are you sure you want to leave? You have unsaved changes!",
                        ) && setSubpage(JiraSubpage.Create)
                      : setFormState(FormState.Authoring)
                  }
                >
                  Back
                </Button>
                <Button
                  variant="accent"
                  isHidden={formState !== FormState.Authoring}
                  onPress={() => {
                    const form = viewReference.current
                      ?.UNSAFE_getDOMNode()
                      .querySelector("form");

                    if (!form) {
                      return ToastQueue.negative("Form not found", {
                        timeout: 5000,
                      });
                    }

                    if (!form.checkValidity()) return;

                    const formData = new FormData(form as HTMLFormElement);
                    const formObject = Object.fromEntries(formData.entries());

                    mdxEditorRef.current?.setMarkdown(
                      new Template(
                        mdxEditorRef.current?.getMarkdown() ?? "",
                      ).render(formObject as Record<string, string>),
                    );

                    requestAnimationFrame(() => {
                      setFormState(FormState.EditingDescription);
                      scrollToTop();
                    });
                  }}
                >
                  Next
                </Button>
              </ButtonGroup>
              <Button
                variant="accent"
                isHidden={formState !== FormState.EditingDescription}
                onPress={() => {
                  setFormState(FormState.Reviewing);
                  requestAnimationFrame(() => {
                    viewReference.current?.UNSAFE_getDOMNode().scrollIntoView({
                      behavior: "auto",
                    });
                  });
                }}
              >
                Review fields
              </Button>
              <Button
                variant="accent"
                isHidden={formState !== FormState.Reviewing}
                isDisabled={!reviewed || submitTriggered}
                type="submit"
                UNSAFE_style={{ width: "150px" }}
              >
                {submitTriggered ? (
                  <ProgressCircle
                    isIndeterminate
                    aria-label="Loading data..."
                    size="S"
                  />
                ) : (
                  "Submit Jira Ticket"
                )}
              </Button>
            </Flex>
          </section>
        </Form>
      </View>
    </View>
  );
};

function fieldArrayToNameObject(fields: JiraField[]) {
  const obj: Record<string, JiraField & { value: string | null }> = {};
  fields.forEach((field) => {
    if (!obj[field.name]) {
      // Add the value key here in case the field is not present in the prefill response
      obj[field.name] = { value: "", ...field };
    } else {
      console.error(`Duplicate field name found: ${field.name}`);
    }
  });
  return obj;
}

export default JiraP2ECustomFields;

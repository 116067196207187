export default class J2M {
  /**
   * Converts a Markdown string into Jira Wiki syntax.
   *
   * @static
   * @param {string} str - Markdown string to convert to Jira Wiki syntax
   * @returns {string} The Jira Wiki syntax result
   */
  static toJira(str: string): string {
    const map = {
      // cite: '??',
      del: "-",
      ins: "+",
      sup: "^",
      sub: "~",
    };

    return (
      str
        // Tables
        .replace(
          /^((?:\|.*?)+\|)[ \t]*\n((?:\|[ \t]*?-+[ \t]*?)+\|)[ \t]*\n((?:(?:\|.*?)+\|[ \t]*\n?)*)$/gm,
          (match, headerLine, separatorLine, rowstr) => {
            const headers = headerLine.match(/[^|]+(?=\|)/g);
            const separators = separatorLine.match(/[^|]+(?=\|)/g);
            if (headers.length !== separators.length) return match;

            const rows = rowstr.split("\n");
            if (rows.length === 2 && headers.length === 1)
              // Panel
              return `{panel:title=${headers[0].trim()}}\n${rowstr
                .replace(/^\|(.*)[ \t]*\|/, "$1")
                .trim()}\n{panel}\n`;

            return `||${headers.join("||")}||\n${rowstr}`;
          },
        )
        // Bold, Italic, and Combined (bold+italic)
        .replace(/([*_]+)(\S.*?)\1/g, (match, wrapper, content) => {
          switch (wrapper.length) {
            case 1:
              return `_${content}_`;
            case 2:
              return `*${content}*`;
            case 3:
              return `_*${content}*_`;
            default:
              return wrapper + content + wrapper;
          }
        })
        // underline
        .replace(/<u>(.*?)<\/u>/gm, function (match, p1) {
          // Escape plus signs inside the captured group
          const escapedContent = p1.replace(/\+/g, "\\+");
          // Return the new format with plus signs
          return `+${escapedContent}+`;
        })
        // All Headers (# format)
        .replace(/^([#]+)(.*?)$/gm, (match, level, content) => {
          return `h${level.length}.${content}`;
        })
        // Headers (H1 and H2 underlines)
        .replace(/^(.*?)\n([=-]+)$/gm, (match, content, level) => {
          return `h${level[0] === "=" ? 1 : 2}. ${content}`;
        })
        // Ordered lists
        .replace(/^([ \t]*)\d+\.\s+/gm, (match, spaces) => {
          return `${Array(Math.floor(spaces.length / 3) + 1)
            .fill("#")
            .join("")} `;
        })
        // Un-Ordered Lists
        .replace(/^([ \t]*)[*-]\s+/gm, (match, spaces) => {
          return `${Array(Math.floor(spaces.length / 2 + 1))
            .fill("*")
            .join("")} `;
        })
        // Headers (h1 or h2) (lines "underlined" by ---- or =====)
        // Citations, Inserts, Subscripts, Superscripts, and Strikethroughs
        .replace(
          new RegExp(`<(${Object.keys(map).join("|")})>(.*?)</\\1>`, "g"),
          (match, from: keyof typeof map, content) => {
            const to = map[from];
            return to + content + to;
          },
        )
        // Other kind of strikethrough
        .replace(/(\s+)~~(.*?)~~(\s+)/g, "$1-$2-$3")
        // Named/Un-Named Code Block
        .replace(/```(.+\n)?((?:.|\n)*?)```/g, (match, synt, content) => {
          let code = "{code}";
          let codeBody = content;
          if (synt) {
            code = `{code:${synt.replace(/\n/g, "")}}\n`;
            if (synt === "yaml\n") {
              codeBody = codeBody.replace(/\n/g, "  \n");
            }
            if (synt === "plaintext\n") {
              code = "{code}\n";
            }
          }
          return `${code}${codeBody}{code}`;
        })
        // Inline-Preformatted Text
        .replace(/`([^`]+)`/g, "{{$1}}")
        // Images
        .replace(/!\[[^\]]*\]\(([^)]+)\)/g, "!$1!")
        // Images with size
        .replace(
          /<img (?=.*\bsrc="([^"]+)")(?=.*\bwidth="(\d+)")(?=.*\bheight="(\d+)")[^>]*>/g,
          (match, src, width, height) => {
            if (width === "200" || height === "200") {
              return `!${src}|thumbnail!`;
            }
            return `!${src}|width=${width},height=${height}!`;
          },
        )
        // Named Link
        .replace(/\[([^\]]+)\]\(([^)]+)\)/g, "[$1|$2]")
        // Un-Named Link
        .replace(/<([^>]+)>/g, "[$1]")
        // Single Paragraph Blockquote
        .replace(/^>/gm, "bq.")
    );
  }
}
